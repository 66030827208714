import axios from 'axios';

export const getSiteBySiteUrlFromApi = siteUrl =>
  axios.get(`sites/${siteUrl}`)
    .then(response => response.data)
    .catch(error => error);

export const getTokenFromApi = siteId =>
  axios.get(`sites/${siteId}/token`)
    .then(response => response.data)
    .catch(error => error);
