import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from "../views/login/Login";
import Page from "../views/page/Page";
import Privacy from "../views/page/Privacy";
import NotFound from "../views/404/NotFound.jsx";

import Blank from "../layouts/Blank"; // Blank Layout
import Default from "../layouts/Default"; // Layout with Header and Footer

const LayoutRouter = ({ component: Component, layout: Layout, ...rest }) =>
    <Route {...rest} render={props => (
        <Layout><Component {...props} /></Layout>
    )}></Route>;

export const Routers = () => {
    return (
        <BrowserRouter>
            <Switch>
                <LayoutRouter exact path="/" layout={ Blank } component={ Login } />
                <LayoutRouter exact path="/page/:url/" layout={ Default } component={ Page } />
                <LayoutRouter exact path="/page/:url/:index" layout={ Default } component={ Page } />
                <LayoutRouter exact path="/privacy/:url" layout={ Default } component={ Privacy } />
                <LayoutRouter layout={ Blank } component={ NotFound } />
            </Switch>
        </BrowserRouter>
    );
};
