import axios from 'axios';

export const getWorksFromArea = (areaID) =>
  axios
    .get(`api/areas/works/${areaID}`)
    .then((response) => response.data)
    .catch((error) => error);

export const getAllWorks = () =>
    axios
      .get(`api/areas/allWorks`)
      .then((response) => response.data)
      .catch((error) => error);

export const getArea = (areaID) =>
      axios
        .get(`api/areas/show/${areaID}`)
        .then((response) => response.data)
        .catch((error) => error);