import logo from '../../assets/images/gruppo-maggioli.svg'

const Footer = () => {
    return (
        <footer id='footer' className='bg-blue border-top shadow relative '>
          <div className='footer-content'>
            <img src={ logo } alt='Logo Gruppo Maggioli' width='90' />
            <div className='px-2 paragraphs'>
              <p>
                Gruppo Maggioli
              </p>
              <p>
                P.Iva 02066400405
              </p>
              <p>
                Santarcangelo di Romagna (RN)
              </p>
            </div>
          </div>
        </footer>
    );
};

export default Footer;
