import { useState, useEffect } from 'react';
import {getSiteBySiteUrlFromApi, getTokenFromApi} from "../../api/sites.api";
import { getFormBySiteIdFromApi } from "../../api/forms.api";
import { getProvincesListFromApi } from "../../api/provinces.api";
import { sendFormJsonFromApi } from "../../api/send.api";
import {
  Grid,
  Form,
  Card,
  Detail,
  InputText,
  BackofficeSelect,
  BackofficeSelectOption,
  BackofficeTextarea,
  Button,
  Radio,
  Banner
} from '@maggioli-design-system/react';
import Spinner from "../../components/spinner/Spinner";
import { getAllWorks, getArea, getWorksFromArea } from '../../api/works.api';

const Page = ({ match }) => {

  const url = match.params.url;
  const index = match.params.index === undefined ? 0 : match.params.index;

  const [area, setArea] = useState({});
  const [site, setSite] = useState({});
  const [form, setForm] = useState([]);
  const [token, setToken] = useState('');
  const [provinces, setProvinces] = useState({});
  const [status, setStatus] = useState({});
  const [works, setWorks] = useState([]);
  const [worksNotFound, setWorksNotFound] = useState(false);
  const [validationErrors, setValidationErrors] = useState({ error: false, message: '', status: '' });

  // eslint-disable-next-line 
  useEffect(() => getSite(), []);

  useEffect(() => {
    if (site.id) {
      getForm();
      getProvinces();
      getToken();
    }
  // eslint-disable-next-line 
  }, [site]);

  const getSite = async () => {
    const response = await getSiteBySiteUrlFromApi(url);
    if (response.data) {
      setSite(response.data);
      return;
    } 

    setStatus({
      error: true,
      message: 'Oops.. nessun sito trovato.',
      status: true,
    });
  }

  const getToken = async () => {
    const response = await getTokenFromApi(site.id);
    response.token && setToken(response.token);
  }

  const getForm = async () => {
    const response = await getFormBySiteIdFromApi(site.id);
    if (!response.data) {
      setStatus({
        error: true,
        message: 'Nessun form trovato per questo sito.',
        status: true,
      });
      return;
    }

    if (response.data.length < parseInt(index) + 1) {
     setStatus({
        error: true,
        message: 'Errore nel caricamento del form.',
        status: true,
      });
      return;
    }
    setForm(response.data);
    if (response.data.length > 0 && response.data[index].hidden.professione !== '') {
      getWorks(response.data[index].hidden.professione);
    }
    setStatus({ error: false, message: 'Form trovato.', status: true, });
  }

  const getProvinces = async () => {
    const response = await getProvincesListFromApi();
    response.data && setProvinces(response.data);
  }

  const inputTypeHandler = (inputObj) => {
    const { name, type, input, label, placeholder, options } = inputObj;

    if (input === 'select') {
      return <BackofficeSelect icon="text-extract" label={label} name={name} placeholder='Scegli una delle opzioni...'>
                { options !== undefined && options.length > 0 && options.map((elem, index) => 
                  <BackofficeSelectOption key={index} value={index === 0 ? '' : elem.value}>{elem.text}</BackofficeSelectOption>)}
            </BackofficeSelect>
    }

    if (input === 'textarea') {
      return <BackofficeTextarea icon='text-description' label={label} name={name} placeholder={placeholder}/>
    }
    
    if (input === 'input') {
      const icon = type === 'email' ? 'document-email' : 'text-title';
      return <InputText icon={icon} label={label} name={name} placeholder={placeholder}/>
    }
  }

  const privacyHandler = (e, index) => <Grid key={index}>
    <h5 dangerouslySetInnerHTML={{ __html: e.description }}></h5>
    <Grid columns='4' gutter='small'>
      { e.fields.map((element, i) => 
      <Radio key={i + element.name} value={element.options.value} name={element.name}>
        {element.options.text}
      </Radio>) }
    </Grid>
  </Grid>;

  const privacyFields = () => {
    return form[index].privacy.filter((e) => e.description || e.fields.length)
                              .map((element, index) => privacyHandler(element, index));
  }

  const inputFields = () => {
    return form[index].input.sort((e, d) => e.input.localeCompare(d.input))
                            .filter((e) => (e.input || e.type))
                            .filter(e => e.type !== 'hidden')
                            .map((element, index) => <div key={index}>{inputTypeHandler(element)}</div>);
  }

  const emailValidation = (email) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(String(email).toLowerCase());
  }

  const getWorks = (areaID) => {
    if (areaID === 'Tutte') {
      getAllWorks().then(res => {
        if (res.status) {
          setWorks(res.data);
        }
      })
    } else {
      getArea(areaID).then(res => {
        if (res.status) {
          setArea(res.data);
        }
      });
      getWorksFromArea(areaID).then(res => {
        if (!res.status) {
          setWorksNotFound(true);
          return;
        }
        setWorks(res.data);
      })
    }
  }

  const provinceFields = () => {
    if (Object.keys(provinces).length) {
     return provinces.map((e, index) => 
        <BackofficeSelectOption key={ index } value={e.value}>{e.text}</BackofficeSelectOption>)
    }
    return [];
  }

  const error = () => {
    if ((Object.keys(status).length && status.error)) {
      return status.message;
    } else if (!Object.keys(status).length) {
      return <Spinner />;
    }
  }

  const validationMessage = () => {
    if (validationErrors.message !== '') {
      return <Banner status={validationErrors.error ? 'error' : 'success'} className="border-radius-small">
        <Detail>{validationErrors.message}</Detail>
      </Banner>
    }
  }  

  const privacyCheck = (formData) => {
    let check = true;
    form[index].privacy.forEach(elem => check = formData.get(elem.fields[0].name) !== '' && check);

    if (formData.get('trattamento') !== '1') {
      setValidationErrors({ error: true, message: 'Il trattamento dati è obbligatorio.' });
      return false;
    }
    if (!check) {
      setValidationErrors({ error: true, message: 'Selezionare un\'opzione per ciascun campo privacy.' });
    }
    return check;
  }

  const checkInputFields = (formData) => {
    if (formData.get('nome') === null || formData.get('cognome') === null || formData.get('email') === null) {
      setValidationErrors({ error: true, message: '"Nome", "cognome" o "email" non trovati.' });
      return false;
    }
    if (formData.get('nome') === '' || formData.get('cognome') === '') {
      setValidationErrors({ error: true, message: 'Nome e cognome sono obbligatori.' });
      return false;
    }
    if (!emailValidation(formData.get('email'))) {
      setValidationErrors({ error: true, message: 'Email non valida.' });
      return false;
    }
    if (!!form[index].provincia && formData.provincia === '') {
      setValidationErrors({ error: true, message: 'La provincia è obbligatoria.' });
      return false;
    }
    return true;
  }

  const consensoInformato = () => <div>
      <input type="hidden" name="trattamento" value="1"/>
      <p dangerouslySetInnerHTML={{ __html: form[index]['caption']['consenso_informato'] }}></p>
    </div>

  const onClickSubmit = async (e) => {
    e.preventDefault();

    window.scrollTo(0, document.body.scrollHeight);

    const tmpFormData = new FormData(e.target);
    const payload = { 'token' : token };

    if(!privacyCheck(tmpFormData) || !checkInputFields(tmpFormData)) {
      return;
    }

    for (const [key, value] of tmpFormData.entries()) {
        payload[key] = value;
    }

    // Campo inserito per salvare la professione sulla custom table sotto il suo nome 
    if (payload.note && payload.note !== '') {
      payload['professione'] = payload.note; 
    }

    const response = await sendFormJsonFromApi(payload);
    if (!response.status) {
      setValidationErrors({ error: true, message: response.message });
    } else {
      setValidationErrors({ error: false, message: 'Messaggio inviato con successo.', status: 'success' });
    }
  }

  const hiddenFields = () => { 
    const hidden = form[index].hidden;
    const fields = form[index].input.filter(e => e.type === 'hidden').map((e, i) => 
                                          <input key={i} type="hidden" name={e.name} value={e.placeholder}/>);
    return <div>
      {fields}
      <input type="hidden" name="micro" value={hidden.micro}/>
      <input type="hidden" name="macro" value={hidden.macro}/>
      <input type="hidden" name="area_tematica" value={hidden.area_tematica}/>
      <input type="hidden" name="servizio" value={form[index].caption.servizio}/>  
      <input type="hidden" name="custom" value={form[index].caption.custom}/>
    </div>
  }
  
  return (
    ! error() && Object.keys(form).length
      ? <>
        <>
          <Form onSubmit={e => onClickSubmit(e)}>
            <Card >

              <h3 className='informative-title'>
                Informativa sul trattamento dei dati personali resa ai sensi dell’art. 13 Regolamento UE 2016/679 (“GDPR”)
              </h3>

              { inputFields() }

              { works.length > 0 &&
              <BackofficeSelect label="Professione" icon="work-project" name="note">
                <BackofficeSelectOption value=''>Scegli una professione...</BackofficeSelectOption>
                { works.sort((a, b) => a.title.localeCompare(b.title)).map((e, index) => 
                <BackofficeSelectOption key={ index } value={e.title}>{e.title}</BackofficeSelectOption>) }
              </BackofficeSelect> }

              { worksNotFound && area !== null &&
              <BackofficeSelect label="Professione" icon="work-project" name="note">
                <BackofficeSelectOption value={area.title}>{area.title}</BackofficeSelectOption>)
              </BackofficeSelect> }

              { !!form[index].provincia && 
              <BackofficeSelect label='Provincia' icon='data-view' name='provincia'>
                { provinceFields() }
              </BackofficeSelect> }

              { form[index] && form[index]['caption']['consenso_informato'] !== ''  && consensoInformato() }

              { privacyFields() }

              { hiddenFields() }              
              
              <Button icon='crud-save' type="submit" disabled={validationErrors.status === 'success'}>Invia</Button>

              { validationMessage() }
            </Card>
          </Form>
        </>
      </> : error()
  );
};

export default Page;
