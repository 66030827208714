import { useState, useEffect, useCallback } from "react";
import { getSiteBySiteUrlFromApi } from "../../api/sites.api";
import { getVersionByFunctionalityFromApi } from "../../api/documents.versions.api";
import Spinner from "../../components/spinner/Spinner";
import { Card } from "@maggioli-design-system/react";

const Privacy = ({ match }) => {
  const url = match.params.url;
  const [site, setSite] = useState({});
  const [document, setDocument] = useState({});
  const [status, setStatus] = useState({});

  const getSite = useCallback(async () => {
    const response = await getSiteBySiteUrlFromApi(url);
    if (response.data) {
      setSite(response.data);
      return setStatus({
        error: false,
        message: "Sito trovato",
        status: true,
      });
    }
    setStatus({
      error: true,
      message: "Sito non trovato",
      status: true,
    });
  }, [url]);

  const getDocument = useCallback(async () => {
    const response = await getVersionByFunctionalityFromApi(site.site_functionality_id);
    if (response.data) {
      setDocument(response.data);
      return setStatus({
        error: false,
        status: true,
        message: "Documento trovato.",
      });
    }
    setStatus({
      error: true,
      message: "Nessun documento trovato",
      status: true,
    });
  }, [site]);

  useEffect(() => {
    getSite();
  }, [getSite]);

  useEffect(() => {
    site.site_functionality_id && getDocument();
  }, [site, getDocument]);

  let error = "";
  if (Object.keys(status).length && status.error) {
    error = status.message;
  } else if (!Object.keys(status).length) {
    error = <Spinner />;
  }

  const _replaceDocument = (arg) => {
    let documentCookie = `<h3>Cookie</h3>
    <p>I cookie sono piccoli file di testo che i siti web visitati dall’utente inviano al suo terminale, dove
    vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo
    utente.</p>
    <p>Tipicamente i cookie possono essere installati:</p>
    <ul>
    <li>direttamente dal proprietario e/o responsabile del sito web (c.d. cookie di prima parte);</li>
    <li>da soggetti diversi dal titolare del sito web visitato dall’utente (c.d. cookie di terza parte)
    e ciò in virtù della presenza sul sito consultato di elementi riconducibili a siti o da web
    server diversi (ad esempio immagini, specifici link a pagine di altri domini ecc..).</li>
    </ul>
    <p>Solo in relazione ai primi, Maggioli si qualifica come Titolare del trattamento.</p>
    <h4>Tipologie di cookie</h4>
    <p>I cookie sono usati per differenti finalità (come, ad esempio, esecuzione di autenticazioni
    informatiche, monitoraggio di sessioni, ecc..) e, in base alla finalità perseguita da un titolare, è
    possibile distinguerli in diverse categorie:</p>
    <h5>Cookie tecnici</h5>
    <p>I cookie tecnici sono quelli che consentono la normale navigazione di un sito nonché il corretto
    funzionamento dello stesso. Poiché consentono attività essenziali per una ottimale fruizione del
    Sito, hanno la funzione di agevolare la consultazione del sito da parte degli utenti.
    Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal
    titolare o gestore del sito web. Possono essere suddivisi in:</p>
    <ol>
        <li>cookie di navigazione o di sessione: garantiscono la normale navigazione e fruizione del
    sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere
    ad aree riservate);</li>
    <li>cookie analytics: vengono utilizzati per collezionare informazioni sull’uso dei siti e possono
    essere assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per

    raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi
    visitano il sito stesso;</li>
    <li>cookie di funzionalità: permettono all’utente la navigazione in funzione di una serie di
    criteri selezionati (ad esempio, la lingua) al fine di migliorare il servizio reso allo stesso.
    L’utilizzo di tali cookie (di prima o terza parte) è indispensabile per il corretto funzionamento di
    un sito web e dei servizi legati agli stessi siti (ad esempio login o accesso alle funzioni riservate
    nei siti).</li>
    </ol>
    <p>Per l’installazione di tali cookie non è richiesto il preventivo consenso degli utenti.</p>
    <h5>Cookie di profilazione</h5>
    <p>I cookie di profilazione (tra cui rientrando quelli di esperienza,
      misurazione e marketing) sono volti a creare profili relativi all’utente che visita il sito e vengono
    utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente
    nell’ambito della navigazione in rete.</p>
    <p>Per l’installazione dei cookie di profilazione è richiesto il preventivo consenso dell’interessato che
    viene registrato da Maggioli.</p>
    <p>Per dettagli sull’elenco dei cookie, i tempi di conservazione, le modalità di disabilitazione e i
    rispettivi titolari, l’utente è invitato a leggere la cookie policy raggiungibile a <a href="${site.link_cookie}" target="_blank">questo link.</a></p>`;

    if (typeof site.link_cookie !== "undefined" && site.link_cookie !== "") {
      return arg.replace("{{%cookie_policy%}}", replaceNamedChars(documentCookie));
    }
    return arg.replace("{{%cookie_policy%}}", "");
  };

  return !error && Object.keys(document).length ? (
    <>
      <Card>
        <h2>Privacy Policy</h2>
        <p dangerouslySetInnerHTML={{ __html: _replaceDocument(replaceNamedChars(document.document)) }}></p>
        <h3>
          Aggiornamenti <small>ver. {document.version} </small>
        </h3>
        <p>
          La Privacy Policy di {site.title} è valida dal {new Date(document.created_at).toLocaleDateString() + " "}
          ed è soggetta ad aggiornamenti; gli utenti sono pertanto invitati a verificarne periodicamente il contenuto.
        </p>
      </Card>
    </>
  ) : (
    error
  );
};

const replaceNamedChars = (htmlText) => {
  if (!htmlText) {
    return "";
  }
  return htmlText
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("<p>", "<div>")
    .replaceAll("</p>", "</div>")
    .replaceAll("<br>", "")
    .replaceAll("<h", "<br/><h");
};

export default Privacy;
