import { useState, useEffect, useCallback } from 'react';
import Spinner from '../../components/spinner/Spinner';
import { loginFromApi } from "../../api/auth.api";
import logo from '../../assets/images/gruppo-maggioli.svg';
import {
  BackofficePageLogin,
  BackofficePageLoginBody,
  BackofficePageLoginHeader,
  InputText,
  InputPassword,
  BackofficePageLoginFooter,
  Button,
  Banner
} from '@maggioli-design-system/react';

const Login = ({ history }) => {
  const [auth, setAuth] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [status, setStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInputHandler = (key, value) => {
    setAuth({
      ...auth,
      [key]: value
    })
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if (user !== null || token !== null)
      return history.push('/admin/')
  }, [history]);

  useEffect(() => {
    if (Object.keys(status).length) {
      setTimeout(() => setStatus({}), 3500);
    }
  }, [status]);

  const loginDataHandler = useCallback((token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    return window.location.href = '/admin/';
  }, []);

  const login = useCallback(async () => {
    setIsLoading(true);
    const { email, password } = auth;

    if (!email || !password)
      return;

    const response = await loginFromApi(auth);
    setIsLoading(false);

    if (response.status) {
      return loginDataHandler(response.token, response.user);
    }

    setStatus({
      error: true,
      message: response.response.data.message,
      status: response.response.data.status,
    })
  }, [auth, loginDataHandler]);


  useEffect(() => {
    return setDisabled(!auth.email || !auth.password);
  }, [auth]);

  let loading = '';
  if (isLoading) {
    loading = <Spinner />;
  }

  let error = '';
  if (Object.keys(status).length && status.error) {
    error = <Banner status='error'>{ status.message }</Banner>
  }

  return (
    <BackofficePageLogin>
      <BackofficePageLoginBody>
        <BackofficePageLoginHeader
          logo={ logo }
          title="Maggioli Privacy"
          description="Accedi al servizio"
        />
        {
          isLoading
            ? <div className='text-center'>{ loading }</div>
            : <>
                { error }
                <InputText
                  placeholder='Inserisci il tuo indirizzo email...'
                  onChange={ e => onChangeInputHandler('email', e.target.value) }
                />
                <InputPassword
                  placeholder='Inserisci la tua password...'
                  onChange={ e => onChangeInputHandler('password', e.target.value) }
                />
                <Button disabled={ disabled } onClick={ login }>
                  Accedi
                </Button>
            </>
        }
      </BackofficePageLoginBody>
      <BackofficePageLoginFooter columns="2" />
    </BackofficePageLogin>
  );
};

export default Login;
