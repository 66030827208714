import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'typeface-karla'
import 'typeface-roboto'
import '@maggioli-design-system/styles/dist/css/styles.min.css'
import '@maggioli-design-system/icons'
import '@maggioli-design-system/design-tokens/dist/css/vars-tailwind.css'
import axios from 'axios';

//axios.defaults.baseURL = "http://localhost:8585/";
axios.defaults.baseURL = axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://privacy.maggiolicloud.it/core/' : 'http://localhost:8585/';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
