import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Default = ({ children }) => {
    return (
        <>
            <Header />
            <main id='main'>
                { children }
            </main>
            <Footer />
        </>
    );
}

export default Default;
