import { Grid } from '@maggioli-design-system/react';
import logo from '../../assets/images/gruppo-maggioli.svg';

const Header = () => {
    return ( <
        header className = 'bg-blue h-150p border-bottom shadow' >
        <
        Grid className = 'h-100' >
        <
        img className = 'logo'
        src = { logo }
        alt = 'Logo Gruppo Maggioli'
        width = '90' / >
        <
        /Grid> <
        /header>
    );
};

export default Header;